import React, { Component } from 'react';
import Draggable from 'react-draggable';
import * as validationManager from '../Utils/ValidationManager';
import Modal from 'react-modal';
import ReactTable from 'react-table';
import Contact from './Contact';
import * as globals from '../../Globals/Variables';
import * as AccountModels from './AccountModels';
import * as DropdownModels from './DropdownModels';
import { checkPolicy } from '../Utils/HelperFunctions';

var debounce = require('lodash/debounce');
const FontAwesome = require('react-fontawesome');
const modalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1111',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    top: '8%',
    left: '12%',
    height: '80%',
    width: '75%',
    overflow: 'hidden',
  },
};
const styleOverrides = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    bottom: 'unset',
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    width: '60%',
    height: '75%',
    top: '2%',
    left: '20%',
    // pointerEvents: "none"
  },
};
function getINITIAL_STATE(that) {
  let policies = that.props.user.firmPolicies;
  return {
    searchText: '',
    viewContactModalOpen: false,
    selectedContactId: null,
    selectedContactData: null,
    pageTitle: 'View',
    data: [],
    pagesize: 10,
    page: 0,
    rowCount: 0,
    loading: true,
    orderby: '&$orderby=LastName',
    enrollSignersEdit: checkPolicy(policies[globals.UI_ENROLLMENT_SIGNER_EDIT]),
    columns: [
      {
        Header: () => {
          return <div className="select-div">Select</div>;
        },
        accessor: 'Checkboxes',
        Cell: that.checkboxCell,
        sortable: false,
      },
      {
        accessor: 'contactname',
        Header: 'Contact',
        Cell: that.textCell,
      },
      {
        accessor: 'address',
        Header: 'Address',
        Cell: that.textCell,
      },
      {
        accessor: 'title',
        Header: 'Title',
        Cell: that.textCell,
      },
      {
        accessor: 'company',
        Header: 'Company',
        Cell: that.textCell,
      },
      {
        accessor: 'email',
        Header: 'Email',
        Cell: that.textCell,
      },
    ],
  };
}
export default class SearchContact extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
  }

  componentDidMount() {
    let search =
      this.props.accountData.ACCOUNT_SIGNER_LINK &&
      this.props.accountData.ACCOUNT_SIGNER_LINK[this.props.signerIndex] &&
      this.props.accountData.ACCOUNT_SIGNER_LINK[this.props.signerIndex].CONTACT_DETAIL
        ? this.props.accountData.ACCOUNT_SIGNER_LINK[this.props.signerIndex].CONTACT_DETAIL.LastName
        : '';
    this.setState({ searchText: search, searchText: search });
    this.searchContact(search);
  }

  searchContact = debounce(async (searchName) => {
    let { user, services, accountData } = this.props;
    let { orderby, expand, page, pagesize } = this.state;
    this.setState({ loading: true });
    if (searchName.indexOf('&') !== -1) {
      searchName = searchName.substring(0, searchName.indexOf('&')) + '%26' + searchName.substring(searchName.indexOf('&') + 1, searchName.length);
    }
    let filter = searchName
      ? `(startswith(LastName,'${searchName}') or startswith(FirstName,'${searchName}'))`
      : `(contains(LastName,'${searchName}') or contains(FirstName,'${searchName}'))`;

    let list = '';
    if (this.props.selectedSigners) {
      for (let i in this.props.selectedSigners) {
        if (this.props.validSigners.includes(parseInt(i))) {
          list = list.length > 0 ? `${list}, '${this.props.selectedSigners[i].ContactDetailID}'` : `'${this.props.selectedSigners[i].ContactDetailID}'`;
        }
      }
      if (list.length > 0 && this.props.isNewSigner) {
        filter = `(DetailID  in (${list}) eq false) and ${filter}`;
      }
    }

    expand = '&$expand=CONTACT($expand=CONTACT_TYPE)';
    //When user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER] is set and - filter on just the signers tied to the policy value
    if (user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER] && user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER].Value) {
      //Get the PII value if sanatized or tokenized
      if (accountData[user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER].Value + '-token']) {
        let accounts = await AccountModels.getPII({
          user,
          services,
          accountId: accountData['AccountID'],
        });
        accountData = accounts[0];
      }
      //Add ReferenceID to filter
      filter = `(CONTACT/ReferenceID eq '${accountData[user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER].Value]}') and ${filter}`;
    }

    filter = '&$filter=' + filter + " and (CONTACT/CONTACT_TYPE/ContactType ne 'Company') and (CONTACT/CONTACT_TYPE/ContactType ne 'Firm')";
    let { values, count } = await DropdownModels.getContactsGrid({
      user,
      services,
      filter,
      orderby,
      expand,
      page,
      pagesize,
    });

    let temp = [];
    this.setState({ data: temp });
    temp = values.map((contact) => {
      return {
        DetailID: contact.DetailID,
        contactname: contact.LastName + ', ' + contact.FirstName,
        address: contact.Address,
        title: contact.Title,
        company: contact.Company,
        email: contact.Email,
        data: contact,
      };
    });
    this.setState({ data: temp, loading: false, rowCount: count });
  }, 1000);

  onSortedChange = (obj) => {
    let tempBaseOrderby = '';
    let desc = !obj[0].desc ? ' desc' : '';
    switch (obj[0].id) {
      case 'contactname':
        tempBaseOrderby = `&$orderby=LastName${desc}`;
        break;
      case 'address':
        tempBaseOrderby = `&$orderby=Address${desc}`;
        break;
      case 'title':
        tempBaseOrderby = `&$orderby=Title${desc}`;
        break;
      case 'company':
        tempBaseOrderby = `&$orderby=Company${desc}`;
        break;
      case 'email':
        tempBaseOrderby = `&$orderby=Email${desc}`;
        break;
      default:
        tempBaseOrderby = this.state.orderby;
        break;
    }
    this.setState({ orderby: tempBaseOrderby }, () => {
      this.searchContact(this.state.searchText);
    });
  };

  textCell = (cellInfo) => {
    return (
      <div
        title={this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
        className={this.state.hoveredRow === cellInfo.index ? `clickable-row` : ''}
        onMouseEnter={() => {
          this.setState({ hoveredRow: cellInfo.index });
        }}
        onMouseLeave={() => {
          this.setState({ hoveredRow: null });
        }}
        onClick={() => {
          this.onViewClick(this.state.data[cellInfo.index].DetailID);
        }}
      >
        <input
          id={cellInfo.index + cellInfo.column.id}
          className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          key={cellInfo.index + cellInfo.column.id}
          readOnly
          value={this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
        />
      </div>
    );
  };

  checkboxCell = (cellInfo) => {
    return (
      <div className="grid-radio">
        <input
          id={cellInfo.index + cellInfo.column.id}
          type="radio"
          name="signer"
          className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          key={cellInfo.index + cellInfo.column.id}
          disabled={false}
          onChange={(e) => {
            this.handleContactSelect(this.state.data[cellInfo.index].CustodianID, this.state.data[cellInfo.index].data);
          }}
        />
      </div>
    );
  };

  handleClearClick = () => {
    if (this.state.searchText !== '') {
      this.setState({ searchText: '' });
      this.searchContact('');
    }
  };

  handleContactSelect = (pk, val) => {
    let obj = Object.assign({}, this.props.accountData);
    if (!obj.ACCOUNT_SIGNER_LINK || obj.ACCOUNT_SIGNER_LINK.length === 0) {
      obj.ACCOUNT_SIGNER_LINK = [{}];
      //default contact to preferred
      //obj.ContactID = val.ContactID;
      //obj.ContactDetailID = val.DetailID;
    } else if (obj.ACCOUNT_SIGNER_LINK.length === this.props.signerIndex) {
      obj.ACCOUNT_SIGNER_LINK.push({});
    }

    val.FullName = val.FirstName + ' ' + val.LastName;
    obj.ACCOUNT_SIGNER_LINK[this.props.signerIndex].CONTACT_DETAIL = val;
    obj.ACCOUNT_SIGNER_LINK[this.props.signerIndex].ContactID = val.ContactID;
    obj.ACCOUNT_SIGNER_LINK[this.props.signerIndex].ContactDetailID = val.DetailID;
    if (this.props.capacities) {
      let defaultVal = this.props.capacities.find((Element) => Element.label === 'Authorized Signatory');
      obj.ACCOUNT_SIGNER_LINK[this.props.signerIndex].CapacityID = defaultVal;
    }

    this.props.updateAccountData(obj);
    this.props.setValidSigner(this.props.signerIndex);
    this.props.closeModal();
  };

  onContactSearchChange = (e) => {
    this.setState({ searchText: e.target.value, selectedCustodianId: null, selectedCustodianData: null, page: 0 }, () => {
      this.searchContact(this.state.searchText);
    });
  };

  onViewClick = (id) => {
    this.setState({ contactDetailId: id }, () => {
      this.setState({
        viewContactModalOpen: true,
        contactType: 'view',
        pageTitle: 'View',
      });
    });
  };

  onNewClick = () => {
    // this.props.setCurrentScreen('signer', 'addSigner');
    // this.props.closeModal();
    this.setState({
      viewContactModalOpen: true,
      contactType: 'add',
      pageTitle: 'Add',
    });
  };
  onPageSizeChange = (size) => {
    this.setState({ pagesize: size, page: 0, loading: true }, () => {
      this.searchContact(this.state.searchText);
    });
  };

  refreshGrid = () => {
    this.searchContact(this.state.searchText);
  };

  onPageChange = (index) => {
    this.setState({ page: index, loading: true }, () => {
      this.searchContact(this.state.searchText);
    });
  };

  setPageTitle = (title) => {
    this.setState({ pageTitle: title });
  };

  closeModalDetail = () => {
    this.setState({
      viewContactModalOpen: false,
    });
  };

  isAnyModalOpen = () => {
    const { viewContactModalOpen } = this.state;
    return viewContactModalOpen;
  };

  handlePages = (pages) => {
    return pages === 0 ? 1 : pages; // DEV-876 Dont let pages = 0, default to 1 or issues with jumpto occurs
  };

  render() {
    const { contactType, enrollSignersEdit } = this.state;
    const disabled = this.isAnyModalOpen();
    return (
      <div>
        <div className="fullmodal handle">
          <div className="fullmodal_title">
            <div className="fullmodal_title_add">Search Signer</div>
          </div>

          <div className="sidemodal_addnew_x" onClick={this.props.closeModal}>
            <FontAwesome name="xbutton" className="fa-times" />
          </div>
        </div>
        <div className="search-modal-container">
          <div className="header-myenrollments">
            <div className="custodian-modal-search">
              <input
                disabled={disabled}
                id="search-contact-input"
                className="custodian-search"
                placeholder="Search..."
                onChange={(e) => {
                  this.onContactSearchChange(e);
                }}
                value={this.state.searchText}
              />
              <FontAwesome
                name="xbutton"
                className="fa-times fa-times-clear clear-search-popup"
                onClick={() => {
                  this.handleClearClick();
                }}
              />
            </div>
            {enrollSignersEdit && (
              <div
                id="search-contact-new-contact"
                className="generic-button-primary no-margin myenrollments-button"
                onClick={() => {
                  this.onNewClick();
                }}
              >
                New
              </div>
            )}
          </div>
          <div className="table-container">
            <ReactTable
              data={this.state.data}
              columns={this.state.columns}
              page={this.state.page || 0}
              pages={this.handlePages(Math.ceil(this.state.rowCount / this.state.pagesize))}
              pageSize={this.state.pagesize}
              className="-striped -highlight grid"
              onSortedChange={this.onSortedChange} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
              loading={this.state.loading}
              manual={true}
              getTrProps={this.getTrProps}
            />
          </div>
        </div>
        {this.state.viewContactModalOpen && (
          <Modal isOpen={this.state.viewContactModalOpen} style={styleOverrides}>
            <Draggable handle=".handle">
              <div>
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">{this.state.pageTitle} Signer</div>
                  </div>

                  <div
                    className="sidemodal_addnew_x"
                    onClick={() => {
                      this.setState({ viewContactModalOpen: false });
                    }}
                  >
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <div className="search-modal-container">
                  <Contact
                    user={this.props.user}
                    services={this.props.services}
                    contactFor={'signer'}
                    type={contactType}
                    closeSearchModal={() => {
                      this.setState({ viewContactModalOpen: false });
                    }}
                    closeViewModal={this.props.closeModal}
                    closeDetailModal={this.closeModalDetail}
                    handleContactSelect={this.handleContactSelect}
                    contactDetailId={this.state.contactDetailId}
                    updateAccountData={this.props.updateAccountData}
                    setCurrentScreen={this.props.setCurrentScreen}
                    signerIndex={this.props.signerIndex}
                    setValidSigners={this.props.setValidSigners}
                    validSigners={this.props.validSigners}
                    accountData={this.props.accountData}
                    refreshGrid={this.refreshGrid}
                    setPageTitle={this.setPageTitle}
                    isModal={true}
                    crmData={this.props.crmData}
                    hideSignerAddEdit={!enrollSignersEdit}
                  />
                </div>
                {/* {this.renderAccountDetails()} */}
              </div>
            </Draggable>
          </Modal>
        )}
      </div>
    );
  }
}
